import { useEffect, useState } from "react";
import Input from "./input";
import { useTranslation } from "react-i18next";
import { geoIds } from "./Map/data/geoIdData";
import getPlaceCensusTract from "../api/map/getPlaceCensusTract";

const SuggestionList = ({
	address,
	getInputProps,
	loading,
	suggestions,
	getSuggestionItemProps,
}) => {
	const { t } = useTranslation();
	const optionClassName =
		"text-gray-900 cursor-default select-none relative py-2 pl-3 pr-9 hover:text-white hover:bg-yellow-orange-600";
	const whitelistedCities = [
		"Aliso Viejo",
		"Anaheim",
		"Brea",
		"Buena Park",
		"Costa Mesa",
		"Cypress",
		"Dana Point",
		"Fountain Valley",
		"Fullerton",
		"Garden Grove",
		"Huntington Beach",
		"Irvine",
		"La Habra",
		"La Palma",
		"Laguna Beach",
		"Laguna Hills",
		"Laguna Niguel",
		"Laguna Woods",
		"Lake Forest",
		"Los Alamitos",
		"Mission Viejo",
		"Newport Beach",
		"Orange",
		"Placentia",
		"Rancho Santa Margarita",
		"San Clemente",
		"San Juan Capistrano",
		"Santa Ana",
		"Seal Beach",
		"Stanton",
		"Tustin",
		"Villa Park",
		"Westminster",
		"Yorba Linda",
	];
	const blacklistedIds = ["ChIJuf095X7c3IARFzJpcxG3_ek"];
	const [filteredSuggestions, setFilteredSuggestions] = useState([]);
	const [previousSuggestions, setPreviousSuggestions] = useState([]);
	const memoizedResults = new Map();

	const getPlaceCensusTractMemoized = async (placeId: any) => {
		if (memoizedResults.has(placeId)) {
			return memoizedResults.get(placeId);
		}
		const response = await getPlaceCensusTract(placeId);
		memoizedResults.set(placeId, response);
		return response;
	};

	const getFilteredSuggestions = async () => {
		const filtered = [];
		try {
			for (const suggestion of suggestions) {
				const { placeId } = suggestion;
				const { data } = await getPlaceCensusTractMemoized(placeId);
				const { census_tract: censusTract } = data;
				if (geoIds.includes(censusTract)) {
					filtered.push(suggestion);
				}
			}
		} catch (err) {
			console.log(err);
		} finally {
			setFilteredSuggestions(filtered);
		}
	};

	const areEqualSuggestions = (previous, current) => {
		if (previous.length !== current.length) return false;
		for (let i = 0; i < previous.length; i++) {
			if (previous[i]?.placeId !== current[i]?.placeId) {
				return false;
			}
		}
		return true;
	};

	useEffect(() => {
		if (!areEqualSuggestions(previousSuggestions, suggestions)) {
			getFilteredSuggestions();
		}
		setPreviousSuggestions(suggestions);
	}, [suggestions]); // eslint-disable-line

	return (
		<div className="w-full">
			<span className="inline-block w-full rounded-md shadow-sm">
				<Input
					id="location-search-input"
					{...getInputProps({
						placeholder: t("search_by_address") + "...",
						className: `appearance-none w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none text-xl`,
						autoFocus: false,
						type: null,
					})}
				/>
			</span>
			{address && (
				<div className="z-10 fixed mt-1 rounded-md bg-white shadow-lg">
					{loading && (
						<div className={optionClassName}>Loading...</div>
					)}
					{filteredSuggestions.map((suggestion) => {
						const cityStateCountry =
							suggestion?.formattedSuggestion?.secondaryText.split(
								", "
							);
						const inCalifornia = whitelistedCities.includes(
							cityStateCountry[0]
						);
						const className = suggestion.active
							? `${optionClassName} font-semibold`
							: optionClassName;
						if (blacklistedIds.includes(suggestion?.placeId)) {
							return null;
						}
						// const { placeId } = suggestion;
						return (
							<span
								style={
									inCalifornia
										? { display: "default" }
										: { display: "none" }
								}
								key={suggestion.placeId}
							>
								<div
									{...getSuggestionItemProps(suggestion, {
										className,
									})}
								>
									<span>{suggestion.description}</span>
								</div>
							</span>
						);
					})}
				</div>
			)}
		</div>
	);
};

export default SuggestionList;
